import "./projects.css";
import { GlobalStyle } from "../../common/styles/global.js";
import rudist from "../../assets/rudist.svg";
import wip from "../../assets/wip.svg";

export const Projects = () => {
  const projects = [
    {
      name: "Rudist",
      description:
        "Custom Redis-compatible server implemented in Rust, built as a deep dive into Redis internals and Rust’s capabilities. This project combines key Redis features with Rust’s safety and performance advantages, making it an ideal exploration of both systems programming and Redis architecture.",
      techStack:
        "Rust, Redis, Networking, Concurrency, Data Structures, Serialization",
      image: rudist,
      repoLink: "https://github.com/c3llus/rudist",
      blogLink:
        "https://blog.c3llus.dev/posts/rudist-redis-implementation-in-rust/",
    },
    // {
    //   name: "SQnotsoLite",
    //   description: "Attempted SQLite implementation in Rust.",
    //   techStack: "Rust, SQLite, File I/O",
    //   image: wip,
    //   repoLink: "https://github.com/c3llus/sqlnotsolite",
    //   blogLink:
    //     "https://blog.c3llus.dev/posts/sqlnotsolite-sqlite-implementation-in-rust/",
    // },
    {
      name: "Pixel Engine",
      description:
        "A lightweight 2D game engine built in Rust, designed to explore the foundational concepts of game engine development. Pixel Engine features custom rendering, entity management, and input handling, offering a modular framework for creating simple yet dynamic 2D games. This project demonstrates the power of Rust for performance-critical, real-time applications.",
      techStack: "Rust, Rendering, ECS (Entity-Component System), Input Handling",
      image: wip,
      repoLink: "https://github.com/c3llus/pixel-engine",
      blogLink:
        "https://blog.c3llus.dev/posts/building-a-pixel-based-game-engine/",
    },
    // {
    //   name: "GoForge",
    //   description:
    //     "Full-featured scaffolding for creating production-ready applications with modern open-source tools, swiftly.",
    //   techStack: "Go, Distributed Systems, Microservices Architecture, Observability, Containerization, CI/CD, Cloud Infrastructure, API Development",
    //   image: wip,
    //   repoLink: "https://github.com/c3llus-mirror/goforge",
    //   blogLink:
    //     "https://blog.c3llus.dev/posts/goforge-backend-boilerplate-for-rapid-development/",
    // },
    // {
    //   name: "BlockchainExplorer",
    //   description:
    //     "A real-time, multi-chain blockchain explorer that efficiently queries and presents blockchain data (transactions, blocks, addresses).",
    //   techStack:
    //     "Go, Blockchain, Distributed Systems, API Development, Data Indexing, Cloud Infrastructure",
    //   image: wip,
    //   repoLink: "https://github.com/c3llus-mirror/blockchainexplorer",
    //   blogLink:
    //     "https://blog.c3llus.dev/posts/blockchainexplorer-building-a-real-time-blockchain-explorer/",
    // },
    // ...other projects
  ];

  // const wipProjects =[]

  return (
    <>
      <GlobalStyle />
      <div className="main">
        <div id="mainbox">
          <div id="home___tool-bar">
            <div id="home___tool-bar-name">
              <div className="home___namebar-fill"></div>
              <div id="home___namebar">
                <a
                  href="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Marcellus
                </a>
              </div>
              <div className="home___namebar-fill"></div>
            </div>
            <div id="home___tool-bar-tiles">
              <div id="home___tool-bar___entries">
                <a
                  className="home___tool-bar-button"
                  href="https://github.com/c3llus"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">GitHub</p>
                  </div>
                </a>
                <a
                  className="home___tool-bar-button"
                  href="https://www.linkedin.com/in/c3llus/"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">LinkedIn</p>
                  </div>
                </a>
                <a
                  className="home___tool-bar-button"
                  href="https://blog.c3llus.dev"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">Blog</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="home__content">
            <div className="projects-section">
              <h2 className="projects-title">My Projects</h2>
              {projects.map((project, index) => (
                <div key={index} className="project-card">
                  <img
                    src={project.image}
                    alt={project.name}
                    className="project-image"
                  />
                  <div className="project-info">
                    <h3 className="project-name">{project.name}</h3>
                    <p className="project-description">{project.description}</p>
                    <p className="project-tech-stack">
                      <strong>Tech Stack:</strong> {project.techStack}
                    </p>
                    <div className="project-links">
                      <a
                        href={project.repoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on GitHub
                      </a>
                      <span> | </span>
                      <a
                        href={project.blogLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Read Tech Blog
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
